import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import loadable from '@loadable/component'

import ContentContainer from '../UI/ContentContainer'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton'
import ThreeSteps from '../UI/ThreeSteps'
// import MmeModal from '../UI/Modal'

const MmeModal = loadable(() => import('../UI/Modal'))
const CreditScoreHowItWorks = () => {
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const queriedSteps = useStaticQuery(graphql`
    query HowItWorksCreditScoreQuery {
      contentfulComponentBenefitsContainer(page: {eq: "CSPage/HowItWorks"}) {
        page
        benefits {
          header {
            childMarkdownRemark {
              html
            }
          }
          icon {
            gatsbyImageData(placeholder:NONE)
            title
            file {
              contentType
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)

  return (
    <ContentContainer background="var(--base-gray)">
      <h2 className="text-center" style={{ marginBottom: 24 }}>
        How the process works
      </h2>
      <ThreeSteps
        data={queriedSteps.contentfulComponentBenefitsContainer.benefits}
        dashedLine={true}
      />
      <HomeButton
        height={BUTTON_SIZE.HEIGHT_L}
        padding={BUTTON_SIZE.PADDING_L}
        fontSize={BUTTON_SIZE.FONT_SIZE_L}
        background="#474ed3"
        backgroundHover="#3f45ba"
        color={BUTTON_COLOR.TEXT_COLOR_WHITE}
        text="Get My Score"
        handleClick={handleClickOpen}
      />
      <MmeModal open={open} handleClose={handleClose} />
    </ContentContainer>
  )
}

export default CreditScoreHowItWorks
