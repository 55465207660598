import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import loadable from '@loadable/component'

import ContentContainer from '../UI/ContentContainer'
import ImageCard from '../UI/Card/ImageCard'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton'
// import MmeModal from '../UI/Modal'

const MmeModal = loadable(() => import('../UI/Modal'))
// This component is used in '/credit-score-club' and "/credit-score" pages, because the contents are the same.
const CreditScoreGetMore = ({background}) => {
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const queriedCreditScoreGetMore = useStaticQuery(graphql`
    query CreditScoreGetMore {
      contentfulComponentTopicDescAndImgContainer (
        page: { eq: "/credit-score(credit-score-club)" }
      ) {
        heading
        subheading
        imageWithContent {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(placeholder:NONE)
            title
          }
        }
      }
    }
  `)
  const { heading, subheading, imageWithContent } = queriedCreditScoreGetMore.contentfulComponentTopicDescAndImgContainer
  return (
    <ContentContainer background={background && background}>
      <CreditScoreGetMoreWrapper>
        <h2>{heading}</h2>
        <p className="subheader">{subheading}</p>
        <div className="grid">
          {imageWithContent && imageWithContent.map((card, i) => 
            <ImageCard background={background && background} key={i}>
              <GatsbyImage 
                image={card?.image?.gatsbyImageData} 
                alt={card?.image?.title}
              />
              <h3>{card.title}</h3>
              <div 
                style={{ width: "100%"}}
                dangerouslySetInnerHTML={{
                  __html: card.description.childMarkdownRemark.html,
                }}
              />
            </ImageCard>
          )}
        </div>

        <HomeButton
          height={BUTTON_SIZE.HEIGHT_L}
          padding={BUTTON_SIZE.PADDING_L}
          fontSize={BUTTON_SIZE.FONT_SIZE_L}
          background="#474ed3"
          backgroundHover="#3f45ba"
          color={BUTTON_COLOR.TEXT_COLOR_WHITE}
          text="Get My Score"
          handleClick={handleClickOpen}
        />
        <MmeModal open={open} handleClose={handleClose} />
      </CreditScoreGetMoreWrapper>
    </ContentContainer>
  )
}

const CreditScoreGetMoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .subheader {
    max-width: 560px;
    font-size: 20px;
  }
  .grid {
    width: 100%;
    display: grid;
    max-width: 1024px;
    margin-top: 80px;
    margin-bottom: 80px;
    grid-column-gap: 60px;
    grid-row-gap: 80px;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    > div {
      box-shadow: none;
      width: 100%;
    }
    .desc {
      padding: 0;
    }
  }
  .gatsby-image-wrapper {
    overflow: hidden;
    width: 250px;
    height: 250px;
    margin-bottom: 24px;
    border-radius: 20px;
    img {
      object-position: 100% 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 0;
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    .grid {
      grid-template-columns: 1fr;
    }
  }
`

export default CreditScoreGetMore
